/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useRef, useState } from 'react';
// Modules
import clsx from 'clsx';
// MUI Core
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// MUI Icons
import DoneIcon from '@material-ui/icons/Done';
// FUI
import Typography from '../../fui/v2/Typography';
// Utils
import { HomePage } from '../../utils-ts/contentful/base';
import { Color } from '../../utils-ts/theme';

interface SectionProps {
  id: string;
  pageContent: HomePage;
  previousViewed: boolean;
  viewed: boolean;
}

const useStyles = makeStyles((theme) => ({
  appear: {
    transition: '.6s opacity ease-out',
    willChange: 'opacity',
    'html:not(.no-js) &': {
      opacity: 0,
    },
  },
  appearIn: {
    'html:not(.no-js) &': {
      opacity: 1,
    },
  },
  badge: {
    height: 32,
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: 45,
    },
  },
  badgeContainer: {
    marginTop: theme.spacing(2),
  },
  container: {
    flex: 1,
    zIndex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      padding: '86px 24px 86px 60px',
      maxWidth: 640,
    },
  },
  doneIcon: {
    marginRight: theme.spacing(3),
    color: Color.FRESH,
  },
  featureContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(),
  },
  horizontalDivider: {
    backgroundColor: Color.FRESH,
    height: 1,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    width: 80,
  },
  image: {
    height: 'auto',
    left: 60,
    position: 'relative',
    width: 615,
    [theme.breakpoints.up('sm')]: {
      left: 0,
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      left: 38,
    },
  },
  imageContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    maxWidth: 710,
    overflow: 'hidden',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(8),
    width: '100%',
  },
  paragraph: {
    marginBottom: theme.spacing(3),
    maxWidth: 480,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
  points: {
    marginBottom: theme.spacing(3),
  },
  leftContainer: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      order: 0,
    },
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    order: 1,
  },
  root: {
    color: theme.palette.background.default,
    display: 'flex',
    minHeight: '100%',
    position: 'relative',
    '& > span': {
      backgroundColor: Color.PREMIUM,
      backgroundPosition: '0 100%',
      backgroundSize: 'cover',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transform: 'scale(-1)',
      transition: 'background-color 1s ease-out',
      'html:not(.no-js) &': {
        backgroundColor: 'rgb(99 99 99)',
      },
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      '@supports (scroll-snap-align: start)': {
        scrollSnapAlign: 'start',
      },
    },
  },
  rootAnimated: {
    '& > span': {
      'html:not(.no-js) &': {
        backgroundColor: Color.PREMIUM,
      },
    },
  },
  slideLeft: {
    left: 0,
    position: 'relative',
    transition: 'all .6s ease-out',
    willChange: 'opacity,transform',
    'html:not(.no-js) &': {
      opacity: 0,
      transform: 'translateX(-40px)',
    },
  },
  slideLeftIn: {
    'html:not(.no-js) &': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  slideUp: {
    bottom: 0,
    position: 'relative',
    transition: 'all .4s ease-out',
    willChange: 'opacity,transform',
    'html:not(.no-js) &': {
      opacity: 0,
      transform: 'translateY(30px)',
    },
  },
  slideUpIn: {
    'html:not(.no-js) &': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

const MobileAppSection = React.forwardRef<unknown, SectionProps>(
  function MobileAppSection({ id, pageContent, previousViewed, viewed }, ref) {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);

    const classes = useStyles();

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [animateBackground, setAnimateBackground] = useState(false);
    const [displayBadge, setDisplayBadge] = useState(false);
    const [displayHeadline, setDisplayHeadline] = useState(false);
    const [displayContent, setDisplayContent] = useState(false);
    const [displayImage, setDisplayImage] = useState(false);
    const [displayPoints, setDisplayPoints] = useState<number[]>([]);

    function animateIn() {
      setTimeout(() => {
        setDisplayContent(true);
      }, 50);
      pageContent.section4Features.map((feature, index) =>
        setTimeout(() => {
          setDisplayPoints((prevValue) => [...prevValue, index]);
        }, 300 + 150 * index),
      );
      setTimeout(() => {
        setDisplayBadge(true);
      }, 700);
    }

    useEffect(() => {
      if (viewed && imageRef.current) {
        setDisplayImage(true);
      }

      if (viewed && contentRef.current) {
        setAnimateBackground(true);
        setDisplayHeadline(true);
        setTimeout(
          () => {
            animateIn();
          },
          smDown ? 0 : 400,
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewed]);

    const defaultSrcSet = `${pageContent.section4Image}?w=2400 4000w, 
    ${pageContent.section4Image}?w=1800 2050w, 
    ${pageContent.section4Image}?w=850 850w, 
    ${pageContent.section4Image}?w=600 600w`;

    return (
      <section
        className={clsx(
          classes.root,
          animateBackground ? classes.rootAnimated : '',
        )}
        id={id}
        ref={ref as React.RefObject<HTMLElement>}
      >
        <span
          style={{
            backgroundImage: 'url(/images/vectors/home-overlay.svg)',
          }}
        />
        <Grid container className={classes.container}>
          <Grid item className={classes.leftContainer} xs={12} md={6}>
            <div className={classes.imageContainer}>
              <div
                className={clsx(
                  classes.appear,
                  displayImage && classes.appearIn,
                )}
              >
                <picture>
                  <source
                    srcSet={`${pageContent.section4Image}?w=2400&fm=webp&q=75 4000w, 
                    ${pageContent.section4Image}?w=1800&fm=webp&q=75 2050w, 
                    ${pageContent.section4Image}?w=850&fm=webp&q=75 850w, 
                    ${pageContent.section4Image}?w=600&fm=webp&q=75 600w`}
                    type="image/webp"
                  />
                  <source srcSet={defaultSrcSet} type="image/png" />
                  <img
                    alt="Mobile App"
                    className={classes.image}
                    loading={previousViewed || viewed ? 'eager' : 'lazy'}
                    ref={imageRef}
                    src={pageContent.section4Image}
                    srcSet={defaultSrcSet}
                  />
                </picture>
              </div>
            </div>
          </Grid>

          <Grid item className={classes.rightContainer} xs={12} md={6}>
            <div className={classes.content} ref={contentRef}>
              <div
                className={clsx(
                  classes.slideLeft,
                  displayHeadline ? classes.slideLeftIn : '',
                )}
              >
                <div>
                  <Typography variant="display-xl">
                    {pageContent.section4Title}
                  </Typography>

                  <div className={classes.horizontalDivider} />
                </div>
                <div
                  className={clsx(
                    classes.slideLeft,
                    displayContent ? classes.slideLeftIn : '',
                  )}
                >
                  <Typography className={classes.paragraph} variant="body-xl">
                    {pageContent.section4Content}
                  </Typography>
                </div>
              </div>

              <div className={classes.points}>
                {pageContent.section4Features.map((feature, index) => (
                  <div
                    className={clsx(
                      classes.appear,
                      displayPoints.includes(index) && classes.appearIn,
                    )}
                    key={feature}
                  >
                    <div className={classes.featureContainer}>
                      <DoneIcon className={classes.doneIcon} />
                      <Typography variant="body-xl">{feature}</Typography>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className={clsx(
                  classes.slideUp,
                  displayBadge ? classes.slideUpIn : '',
                )}
              >
                <div className={classes.badgeContainer}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <a
                        href="https://apps.apple.com/au/app/fresh-equities/id1486674685"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          className={classes.badge}
                          alt="App Store"
                          loading="lazy"
                          src="/images/assets/apple-badge.svg"
                        />
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.freshequities.app"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          className={classes.badge}
                          alt="Play Store"
                          loading="lazy"
                          src="/images/assets/google-badge.svg"
                        />
                      </a>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    );
  },
);

export default MobileAppSection;
