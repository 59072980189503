import React, { FC, useEffect, useState } from 'react';
// Modules
import clsx from 'clsx';
import Link from 'next/link';
import { UrlObject } from 'url';
import { useQuery } from '@apollo/client';
// MUI Core
import { makeStyles } from '@material-ui/core/styles';
// FUI
import Button from '../../fui/v2/Button';
// Hooks
import { useFindPermission } from '../hooks/usePermission';
// Graphql
import MeQuery, { MeQueryData } from '../../graphql-ts/queries/MeQuery.graphql';
// Utils
// import { EVENTS } from '../../utils-ts/analytics';
import { href } from '../../utils-ts/pages';

interface AccountButtonProps {
  visitorCTA?: string;
  visitorlinkHref?: string | UrlObject;
}

const useStyles = makeStyles({
  appear: {
    opacity: 0,
    transition: '.1s opacity ease',
    willChange: 'opacity',
  },
  appearIn: {
    opacity: 1,
  },
});

const AccountButton: FC<AccountButtonProps> = ({
  visitorCTA,
  visitorlinkHref,
}) => {
  const { data: meData, loading: meLoading } = useQuery<MeQueryData>(MeQuery);
  const { granted: isQualified, loading: qualifiedLoading } = useFindPermission(
    'investor.offer.view',
  );
  const { granted: isAdmin, loading: adminLoading } =
    useFindPermission('admin.offer.view');

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(meLoading || qualifiedLoading || adminLoading);
  }, [meLoading, qualifiedLoading, adminLoading]);

  const classes = useStyles();

  // function trackBecomeClient() {
  //   window.analytics?.track(EVENTS.BECOME_CLIENT_PRESSED, {
  //     path: router.asPath,
  //     variant: 'Teaser',
  //   });
  // }

  if (meData && meData.me) {
    if (isQualified || isAdmin) {
      return (
        <Link href={href.liveCapitalRaises}>
          <a>
            <Button color="secondary">
              <span
                className={clsx(classes.appear, !loading && classes.appearIn)}
              >
                View live offers
              </span>
            </Button>
          </a>
        </Link>
      );
    }

    return (
      <Link href={href.profile}>
        <a>
          <Button color="secondary">
            <span
              className={clsx(classes.appear, !loading && classes.appearIn)}
            >
              Complete setup
            </span>
          </Button>
        </a>
      </Link>
    );
  }

  return (
    <Link href={visitorlinkHref || href.authSignUp}>
      <a>
        {/* <Button color="secondary" onClick={trackBecomeClient}> */}
        <Button color="secondary">
          <span className={clsx(classes.appear, !loading && classes.appearIn)}>
            {visitorCTA || 'Become a client'}
          </span>
        </Button>
      </a>
    </Link>
  );
};

export default AccountButton;
