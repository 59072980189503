import { gql } from '@apollo/client';

export interface LandingPageStatsQueryData {
  landingPageStats: {
    avgBidSize: number;
    avgRaiseSize: number;
    totalActiveInvestors: number;
    totalBidsProcessed: number;
    totalCapitalRaisesSupported: number;
    totalNumberOfBids: number;
  };
}

export default gql`
  query LandingPageStatsQuery {
    landingPageStats {
      avgBidSize
      avgRaiseSize
      totalActiveInvestors
      totalBidsProcessed
      totalCapitalRaisesSupported
      totalNumberOfBids
    }
  }
`;
