import React from 'react';
export interface Props {
  color: string;

  disabled?: boolean;
  size: number;
}

const Pulse: React.FC<Props> = ({ color, disabled, size }) => {
  return (
    <div
      style={{ backgroundColor: color, width: size, height: size }}
      className="relative rounded-[50%]"
    >
      {!disabled && (
        <div
          style={{ backgroundColor: color, width: size, height: size }}
          className="absolute rounded-[50%] animate-ping"
        />
      )}
    </div>
  );
};

export default Pulse;
