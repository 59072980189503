export const homePageContent = {
  featuredIn: [
    {
      logo: '//images.ctfassets.net/ye2xsl4yl17z/5vCPVMxkSH1svPbXKJeLRr/b95bc702295601807c0079846f978c73/financial-review.png',
      slug: 'financial-review',
      title: 'Financial Review',
      url: 'https://www.afr.com/work-and-careers/leaders/fresh-equities-brings-private-placement-deals-into-the-open-20190301-h1bv5h',
    },
    {
      logo: '//images.ctfassets.net/ye2xsl4yl17z/34lH1wS6hfMUoqcJSLMeGU/8c62581f49b5d3d64b69a7640978f180/asx.png',
      slug: 'asx',
      title: 'ASX',
      url: 'https://www2.asx.com.au/blog/understanding-capital-raisings',
    },
    {
      logo: '//images.ctfassets.net/ye2xsl4yl17z/DuKIhiZxdpw75P25wwQ7D/3f880a8cc984ec810213456ddc9441ca/ausbiz.png',
      slug: 'ausbiz',
      title: 'Ausbiz',
      url: 'https://www.ausbiz.com.au/media/capital-raisings-good-in-good-times-and-bad?videoId=530',
    },
    {
      logo: '//images.ctfassets.net/ye2xsl4yl17z/1WlGS3YxkL2rUX7WK6pqd2/b3e878089489b55dfafd343202fdc123/startcon.png',
      slug: 'startcon-best-fintech-startup-2019',
      title: 'StartCon Best FinTech Startup 2019',
      url: 'https://www.startcon.com/hall-of-fame',
    },
    {
      logo: '//images.ctfassets.net/ye2xsl4yl17z/7u3GSA5D7gzbygDjvacvAj/cc3c9705bda07aabe1f2c408f8efcc68/street-talk.png',
      slug: 'street-talk',
      title: 'Street Talk',
      url: 'https://www.afr.com/street-talk/regulators-set-to-turbocharge-covid-19-capital-raisings-20200329-p54ey2',
    },
    {
      logo: '//images.ctfassets.net/ye2xsl4yl17z/1wtlzlejyjmrQXdv5wpC9u/2ad28830bf16a16229c36fbb031b828e/stockhead.png',
      slug: 'stockhead',
      title: 'Stockhead',
      url: 'https://stockhead.com.au/private-i/brokers-love-it-fresh-equities-wants-to-shake-up-the-market-for-private-placements/amp/',
    },
  ],
  section1Content:
    'Fresh Equities provides simple access to listed capital raises for institutions, family offices and high net worth individuals.',
  section1Title: 'Connecting investors with listed companies raising capital.',
  section2Content:
    'Fresh Equities is Australia’s first and largest independent syndicator and aggregator of raises from Australian listed companies. We’re committed to making the process of participating in capital raises simple and manageable for our clients.',
  section2Title: 'Deal flow, simplified.',
  section2InvestorCount: 450,
  section2InvestorText: 'Active Investors',
  section2BidsCount: 151000000,
  section2BidsText: 'Bids Processed',
  section2TransactionsCount: 604,
  section2TransactionsText: 'Transactions',
  section3Image:
    '//images.ctfassets.net/ye2xsl4yl17z/2xpXC6Pz3nwR0GSAuzFHDm/52c2225e26c9fde0c304d76e56eb6425/teodor-drobota-uyyRJA2an4o-unsplash__1___1_.jpg',
  section3Title: 'Creating opportunity through connection.',
  section3Subtitle: null,
  section3Content: 'empty',
  section3HowItWorks: [
    {
      title: 'Become a client',
      content:
        'Within minutes of finding us you can have an active bidding account and the support of one of our specialist account managers.',
    },
    {
      title: 'Discover offers',
      content:
        'Bid for allocation and receive instant confirmation of your bid. Our team is committed to assisting you every step of the way.',
    },
    {
      title: 'Participate',
      content: 'We’ll send your shares to the brokerage of choice. Easy.',
    },
  ],
  section3Links: [],
  section4DownloadText: 'Download the Fresh Equities app:',
  section4Title: 'Australian capital markets at your fingertips.',
  section4Content:
    'You can do just about anything on your mobile these days, we don’t think capital markets should be any different. Our proprietary technology allows us to provide a simple, personalised and secure solution.',
  section4Features: [
    'Live offer notifications',
    'Secure bid management',
    'Access to specialist support',
  ],
  section4Image:
    '//images.ctfassets.net/ye2xsl4yl17z/5JJZZlWGigPNzSJRRsSOul/bd10b9efd2835156665fb336228446c5/App_store_iPhone_diagonal_angled_1500x1284.png',
  section5Image:
    '//images.ctfassets.net/ye2xsl4yl17z/eCokbUkL32PchrfUSD9Yl/cd83cda3d5a22fe249859cc441779132/oliver-fetter-SleY04bVP9s-unsplash.jpg',
  section5Title: 'We connect companies with investors who care.',
  section5Subtitle: 'We’re here to support big ideas',
  section5Content:
    'Investing in capital raises is about supercharging growth. We specialise in connecting self-directed, high-conviction investors with growth opportunities. Our unique position and scale allows us to support raises with insights as well as funds.\n' +
    '\n' +
    'We are uniquely focused on deal flow and not involved in brokerage, leading corporate activity or research. This allows us to work with all market participants equally.',
  section5Links: [],
  section6AccountTitle: 'Get access to these offers',
  section6AccountContent: 'Create a free account',
  section6Title: 'Looking for more information?',
  section6Content:
    'We work hard to deliver on our promises. If you have any questions or would like to have a confidential conversation, our team is always available -',
};
