// Modules
import { useQuery, useSubscription } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
// FUI
import Pulse from '../../fui/v2/Pulse';
import Typography from '../../fui/v2/Typography';
// GraphQL
import RaiseOfferStatsQuery, {
  RaiseOfferStatsQueryData,
} from '../../graphql-ts/queries/RaiseOfferStatsQuery.graphql';
import RaiseOfferUpsertedSubscription, {
  RaiseOfferUpsertedSubscriptionData,
} from '../../graphql-ts/subscriptions/RaiseOfferUpsertedSubscription.graphql';
// Utils
import { Color } from '../../utils-ts/theme';

interface Props {
  variant?: 'dark' | 'light';
}

const RaiseOfferStats: React.ComponentType<Props> = ({ variant }) => {
  const { data, refetch } =
    useQuery<RaiseOfferStatsQueryData>(RaiseOfferStatsQuery);

  useSubscription<RaiseOfferUpsertedSubscriptionData>(
    RaiseOfferUpsertedSubscription,
    {
      onSubscriptionData: async () => {
        try {
          await refetch();
        } catch (e) {
          Sentry.captureException(e);
        }
      },
    },
  );

  return (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        <div>
          <Pulse
            color={variant === 'dark' ? Color.FRESH : Color.PINE}
            size={8}
          />
        </div>
        <div>
          <Typography
            style={{ color: variant === 'dark' ? Color.FRESH : Color.PINE }}
            variant="body-lg"
          >
            {`${
              data?.raiseOfferStats?.totalLiveRaiseOffers.toLocaleString() ??
              '0'
            } live offer${
              data?.raiseOfferStats?.totalLiveRaiseOffers &&
              data?.raiseOfferStats?.totalLiveRaiseOffers === 1
                ? ''
                : 's'
            } available for bidding`}
          </Typography>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <div>
          <Pulse
            color={variant === 'dark' ? Color.SMOKE : Color.STONE}
            disabled
            size={8}
          />
        </div>
        <div>
          <Typography
            style={{ color: variant === 'dark' ? Color.SMOKE : Color.STONE }}
            variant="body-lg"
          >
            {`${
              data?.raiseOfferStats?.totalPastRaiseOffersInSevenDays.toLocaleString() ??
              '0'
            } offer${
              data?.raiseOfferStats?.totalPastRaiseOffersInSevenDays &&
              data?.raiseOfferStats?.totalPastRaiseOffersInSevenDays === 1
                ? ''
                : 's'
            } closed for bidding in the past 7 days`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default RaiseOfferStats;
