import { gql } from '@apollo/client';

export interface RaiseOfferUpsertedSubscriptionData {
  raiseOfferUpserted: string;
}

const RaiseOfferUpsertedSubscription = gql`
  subscription RaiseOfferUpsertedSubscription {
    raiseOfferUpserted
  }
`;

export default RaiseOfferUpsertedSubscription;
