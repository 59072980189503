import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
// MUI Core
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// MUI Icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// FUI
import Typography from '../../fui/v2/Typography';
// Utils
import { HomePage } from '../../utils-ts/contentful/base';
import { Color } from '../../utils-ts/theme';

interface SectionProps {
  id: string;
  pageContent: HomePage;
  previousViewed: boolean;
  section: 3 | 5;
  viewed: boolean;
}

const useStyles = makeStyles<Theme, { section: SectionProps['section'] }>(
  (theme) => ({
    bgImage: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    container: {
      [theme.breakpoints.up('md')]: {
        minHeight: '100%',
        '@supports (scroll-snap-align: start)': {
          scrollSnapAlign: 'start',
        },
      },
    },
    content: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 500,
      },
    },
    contentWrapper: {
      order: 1,
    },
    containerInner: {
      [theme.breakpoints.up('md')]: {
        minHeight: '100vh',
        height: '100%',
      },
    },
    contentInner: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(6),
      maxWidth: 600,
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        padding: ({ section }) =>
          section === 3 ? '86px 24px 86px 60px' : '86px 60px 86px 24px',
        justifyContent: 'flex-start',
        margin: ({ section }) => (section === 3 ? '0 auto 0 0' : '0 0 0 auto'),
        maxWidth: 640,
      },
    },
    imageContainer: {
      height: 300,
      [theme.breakpoints.up('sm')]: {
        height: 400,
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '100%',
      },
    },
    imageWrapper: {
      order: ({ section }) => (section === 3 ? 0 : 2),
      [theme.breakpoints.down('sm')]: {
        order: '0!important' as any,
      },
    },
    itemTitle: {
      color: Color.JET,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    line: {
      backgroundColor: Color.FRESH,
      display: 'block',
      height: 1,
      width: 70,
    },
    link: {
      alignItems: 'center',
      color: Color.PINE,
      display: 'flex',
      fontSize: '1rem',
      padding: '6px 0',
    },
    linkIcon: {
      marginLeft: theme.spacing(1),
    },
    links: {
      marginTop: theme.spacing(4),
    },
    subtitle: {
      color: Color.JET,
    },
    slideLeft: {
      left: 0,
      position: 'relative',
      transition: 'all .6s ease-out',
      'html:not(.no-js) &': {
        opacity: 0,
        transform: 'translateX(-75px)',
      },
    },
    slideLeftIn: {
      'html:not(.no-js) &': {
        opacity: 1,
        transform: 'translateX(0)',
      },
    },
    slideLeftShort: {
      left: 0,
      position: 'relative',
      transition: 'all .6s ease-out',
      'html:not(.no-js) &': {
        opacity: 0,
        transform: 'translateX(-50px)',
      },
    },
    slideLeftShortIn: {
      'html:not(.no-js) &': {
        opacity: 1,
        transform: 'translateX(0)',
      },
    },
    text: {
      whiteSpace: 'pre-line',
    },
  }),
);

const ImageSection = React.forwardRef<unknown, SectionProps>(
  ({ id, pageContent, previousViewed, section, viewed }, ref) => {
    const classes = useStyles({ section });

    const contentRef = useRef<HTMLDivElement | null>(null);

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [displayBody, setDisplayBody] = useState(false);
    const [displayTitle, setDisplayTitle] = useState(false);

    function animateIn() {
      setDisplayTitle(true);
      setTimeout(() => {
        setDisplayBody(true);
      }, 150);
    }

    useEffect(() => {
      if (viewed && contentRef.current) {
        setTimeout(
          () => {
            animateIn();
          },
          smDown ? 0 : 250,
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewed]);

    const content = {
      imageUrl:
        section === 3 ? pageContent.section3Image : pageContent.section5Image,
      links:
        section === 3 ? pageContent.section3Links : pageContent.section5Links,
      subtitle:
        section === 3
          ? pageContent.section3Subtitle
          : pageContent.section5Subtitle,
      text:
        section === 3
          ? pageContent.section3Content
          : pageContent.section5Content,
      howItWorks: section === 3 ? pageContent.section3HowItWorks : undefined,
      title:
        section === 3 ? pageContent.section3Title : pageContent.section5Title,
    };

    const defaultSrcSet = `${content.imageUrl}?w=3400 4000w,
    ${content.imageUrl}?w=2200 2050w, 
    ${content.imageUrl}?w=850 850w, 
    ${content.imageUrl}?w=600 600w`;

    return (
      <section
        className={classes.container}
        id={id}
        ref={ref as React.RefObject<HTMLElement>}
      >
        <Grid container className={classes.containerInner}>
          <Grid className={classes.imageWrapper} item xs={12} md={6}>
            <div className={classes.imageContainer}>
              <picture>
                <source
                  srcSet={`${content.imageUrl}?w=3400&fm=webp&q=75 4000w,
                ${content.imageUrl}?w=2200&fm=webp&q=75 2050w, 
                ${content.imageUrl}?w=850&fm=webp&q=75 850w, 
                ${content.imageUrl}?w=600&fm=webp&q=75 600w`}
                  type="image/webp"
                />
                <source srcSet={defaultSrcSet} type="image/jpeg" />
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  className={classes.bgImage}
                  alt=""
                  loading={previousViewed || viewed ? 'eager' : 'lazy'}
                  src={content.imageUrl}
                  srcSet={defaultSrcSet}
                />
              </picture>
            </div>
          </Grid>
          <Grid
            className={classes.contentWrapper}
            item
            xs={12}
            md={6}
            ref={contentRef}
          >
            <div className={clsx(classes.containerInner, classes.contentInner)}>
              <div className={classes.content}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div
                      className={clsx(
                        classes.slideLeft,
                        displayTitle ? classes.slideLeftIn : '',
                      )}
                    >
                      <Grid container spacing={3}>
                        {content.subtitle && (
                          <Grid item xs={12}>
                            <Typography
                              className={classes.subtitle}
                              variant="heading-sm"
                            >
                              {content.subtitle}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="display-xl">
                            {content.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <span className={classes.line} />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      className={clsx(
                        classes.slideLeftShort,
                        displayBody ? classes.slideLeftShortIn : '',
                      )}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {content.howItWorks ? (
                            <Grid container spacing={3}>
                              {content.howItWorks.map((item) => (
                                <Grid item key={item.title} xs={12}>
                                  <Typography
                                    className={classes.itemTitle}
                                    variant="heading-sm"
                                  >
                                    {item.title}
                                  </Typography>
                                  <Typography
                                    className={classes.text}
                                    variant="body-xl"
                                  >
                                    {item.content}
                                  </Typography>
                                </Grid>
                              ))}
                            </Grid>
                          ) : (
                            <Typography
                              className={classes.text}
                              variant="body-xl"
                            >
                              {content.text}
                            </Typography>
                          )}
                        </Grid>
                        {content.links.length ? (
                          <Grid item xs={12}>
                            <div className={classes.links}>
                              {content.links.map((link) => (
                                <a
                                  className={classes.link}
                                  href={link.url}
                                  key={link.text}
                                >
                                  {link.text}
                                  <ArrowForwardIcon
                                    className={classes.linkIcon}
                                    fontSize="small"
                                  />
                                </a>
                              ))}
                            </div>
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    );
  },
);

export default ImageSection;
