import React, { FC } from 'react';
// MUI Core
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// Components
import AnchorWrapper from '../common/AnchorWrapper';
// FUI
import Typography from '../../fui/v2/Typography';
// Utils
import { HomePage } from '../../utils-ts/contentful/base';
import { Color } from '../../utils-ts/theme';

interface AsFeaturedInSectionProps {
  pageContent: HomePage;
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  flex: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  mediaLogo: {
    height: 32,
    width: 'auto',
  },
  root: {
    backgroundColor: Color.GREY,
    color: Color.STONE,
  },
}));

const AsFeaturedInSection: FC<AsFeaturedInSectionProps> = ({ pageContent }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <Grid container spacing={3} className={classes.flex}>
          <Grid item xs={12}>
            <Typography variant="button">As Featured In</Typography>
          </Grid>

          {pageContent.featuredIn.map((media) => (
            <Grid key={media.slug} item>
              <AnchorWrapper
                href={media.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                <picture>
                  <source
                    srcSet={`${media.logo}?fm=webp&q=75`}
                    type="image/webp"
                  />
                  <source srcSet={media.logo} type="image/png" />
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    className={classes.mediaLogo}
                    alt={media.slug}
                    loading="lazy"
                    src={media.logo}
                  />
                </picture>
              </AnchorWrapper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default AsFeaturedInSection;
