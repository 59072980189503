import { gql } from '@apollo/client';

export interface MePermissionsQueryData {
  mePermissions: [string] | null;
}

export default gql`
  query MePermissionsQuery {
    mePermissions
  }
`;
