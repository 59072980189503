import { gql } from '@apollo/client';

export interface RaiseOfferStatsQueryData {
  raiseOfferStats: {
    totalLiveRaiseOffers: number;
    totalPastRaiseOffersInSevenDays: number;
  } | null;
}

const RaiseOfferStatsQuery = gql`
  query RaiseOfferStatsQuery {
    raiseOfferStats {
      totalLiveRaiseOffers
      totalPastRaiseOffersInSevenDays
    }
  }
`;

export default RaiseOfferStatsQuery;
