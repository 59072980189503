import React, { FC } from 'react';
// Modules
// MUI Core
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
// FUI
import Typography from '../../fui/v2/Typography';
// Component
import AccountButton from './AccountButton';
// Utils
// import {
//   OFFICE_NUMBER,
//   OFFICE_NUMBER_FORMATTED,
// } from '../../utils-ts/constants';
import { HomePage } from '../../utils-ts/contentful/base';
import { Color } from '../../utils-ts/theme';

interface SectionProps {
  pageContent: HomePage;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
  container: {
    alignItems: 'center',
    backgroundSize: '50%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(8),
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      '@supports (scroll-snap-align: start)': {
        scrollSnapAlign: 'start',
      },
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 700,
    },
  },
  contentInner: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 960,
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
    },
  },
  line: {
    backgroundColor: Color.FRESH,
    display: 'block',
    height: 1,
    width: 70,
  },
  link: {
    alignItems: 'center',
    color: Color.PINE,
    display: 'flex',
    fontSize: '1rem',
    padding: '6px 0',
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  linkIcon: {
    fontSize: '1em',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 12,
    },
  },
  links: {
    marginTop: theme.spacing(4),
  },
  linkSmall: {
    color: Color.PINE,
  },
  text: {
    whiteSpace: 'pre-line',
  },
  title: {
    maxWidth: 400,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
}));

const CallToActionSection: FC<SectionProps> = ({ pageContent }) => {
  const classes = useStyles();

  return (
    <section
      className={classes.container}
      style={{
        background: 'url(/images/vectors/squiggly-1.svg)',
        backgroundSize: '85px',
      }}
    >
      <div className={classes.contentInner}>
        <div className={classes.content}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="display-xl">
                {pageContent.section6Title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.line} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text} variant="body-xl">
                {pageContent.section6Content}{' '}
                <a
                  className={classes.linkSmall}
                  href="mailto:clients@freshequities.com"
                >
                  clients@freshequities.com
                </a>
                {/* {' '}
                or{' '}
                <a className={classes.linkSmall} href={`tel:${OFFICE_NUMBER}`}>
                  {OFFICE_NUMBER_FORMATTED}
                </a> */}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.buttonContainer}>
                <Grid alignItems="center" container spacing={4}>
                  <Grid item xs={12} sm={4} md="auto">
                    <AccountButton />
                  </Grid>
                  {/* <Grid item xs={12} sm={8} md="auto">
                    <Link href={href.about}>
                      <a>
                        <Button
                          color="secondary"
                          endIcon={<ArrowForwardIcon />}
                          variant="text"
                        >
                          Learn more about us
                        </Button>
                      </a>
                    </Link>
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default CallToActionSection;
