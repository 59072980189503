import React, { FC } from 'react';
// Modules
import dynamic from 'next/dynamic';
// MUI Core
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// FUI
import Typography from '../../fui/v2/Typography';
// Components
import AccountButton from './AccountButton';
import NavBar from '../layout/navbar/NavBar';
// Utils
import { HomePage } from '../../utils-ts/contentful/base';
import { href } from '../../utils-ts/pages';
import { Color } from '../../utils-ts/theme';

interface SectionProps {
  pageContent: HomePage;
}

const Bubbles = dynamic(() => import('./Bubbles'), {
  loading: () => null,
  ssr: false,
});

const useStyles = makeStyles((theme) => ({
  carouselWrapper: {
    backgroundColor: Color.SAGE,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: 780,
    justifyContent: 'center',
    overflow: 'hidden',
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(4),
    },
  },
  container: {
    minHeight: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },
  leftContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      marginLeft: 'auto',
      maxWidth: 640,
    },
  },
  leftContent: {
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(16),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 552,
      paddingTop: theme.spacing(20),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  pineText: {
    bottom: theme.spacing(3),
    color: Color.PINE,
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
  },
  rightContainer: {
    position: 'relative',
  },
  root: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
      '@supports (scroll-snap-align: start)': {
        scrollSnapAlign: 'start',
      },
    },
  },
}));

const CarouselSection: FC<SectionProps> = ({ pageContent }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NavBar variant="home" />
      <Grid container className={classes.container}>
        <Grid item xs={12} md={6}>
          <div className={classes.leftContainer}>
            <div className={classes.leftContent}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="display-xl">
                    {pageContent.section1Title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body-xl">
                    {pageContent.section1Content}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AccountButton
                    visitorCTA="Become a client"
                    visitorlinkHref={href.capitalRaises}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item className={classes.rightContainer} xs={12} md={6}>
          <div className={classes.carouselWrapper}>
            <Bubbles />
          </div>
          <Typography
            className={classes.pineText}
            align="center"
            variant="heading-sm"
          >
            We participate in more than 400 transactions a year across all
            sectors and sizes.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default CarouselSection;
