import React, { FC } from 'react';
// Modules
import { BreadcrumbJsonLd } from 'next-seo';
// Components
import { OFFICE_NUMBER } from '../../../utils-ts/constants';
import { JsonLd } from '../../seo/JsonLd';

const LandingSEO: FC = () => {
  const url = 'https://www.freshequities.com/';
  const name = 'Fresh Equities';
  const tel = OFFICE_NUMBER;
  const address = {
    '@type': 'PostalAddress',
    addressLocality: 'Melbourne, Australia',
    postalCode: '3000',
    streetAddress: 'Level 16, 452 Flinders St',
  };
  const business = {
    '@id': url,
    '@type': 'LocalBusiness',
    address,
    name: 'Fresh Equities',
    image: 'https://www.freshequities.com/images/assets/og-image.png',
    sameAs: url,
    telephone: tel,
  };
  const offers = [
    {
      '@type': 'Service',
      serviceType: 'Listed company capital raises',
      provider: business,
      areaServed: {
        '@type': 'Country',
        name: 'Australia',
      },
    },
  ];

  const landingLdData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    description:
      'We provide comprehensive access to listed capital raises for institutional, family office and high net worth investors.',
    name,
    url,
    sameAs: [
      'https://twitter.com/FreshEquities',
      'https://www.linkedin.com/company/freshequities/',
      'https://medium.com/fresh-equities',
    ],
  };

  const freshLdData = {
    '@id': url,
    '@context': 'http://schema.org',
    '@type': 'Organization',
    address,
    email: 'deals(at)freshequities.com',
    founders: [
      {
        '@type': 'Person',
        jobTitle: 'Co-Founder and CEO',
        name: 'Ben Williamson',
      },
      {
        '@type': 'Person',
        jobTitle: 'Co-Founder and CTO',
        name: 'Rhys Davis',
      },
    ],
    name,
    telephone: tel,
    url,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: tel,
        contactType: 'customer service',
        areaServed: {
          '@type': 'Country',
          name: 'Australia',
        },
      },
    ],
  };

  const androidLdData = {
    '@context': 'https://schema.org',
    '@type': 'MobileApplication',
    name: 'Fresh Equities',
    operatingSystem: 'ANDROID',
    applicationCategory: 'Finance',
    installUrl:
      'https://play.google.com/store/apps/details?id=com.freshequities.app',
    offers,
  };

  const iosLdData = {
    '@context': 'https://schema.org',
    '@type': 'MobileApplication',
    name: 'Fresh Equities',
    operatingSystem: 'IOS',
    applicationCategory: 'Finance',
    installUrl: 'https://apps.apple.com/au/app/fresh-equities/id1486674685',
    offers,
  };

  return (
    <>
      <JsonLd data={landingLdData} keyId="landing ld+json data" />
      <JsonLd data={freshLdData} keyId="fresh ld+json data" />
      <JsonLd data={androidLdData} keyId="android ld+json data" />
      <JsonLd data={iosLdData} keyId="ios ld+json data" />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Fresh Equities',
            item: 'https://www.freshequities.com/',
          },
        ]}
      />
    </>
  );
};

export default LandingSEO;
