import React, { useEffect, useRef, useState } from 'react';
// Modules
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import Link from 'next/link';
import numeral from 'numeral';
// MUI Core
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// MUI Icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// Components
import RaiseOfferStats from '../common/RaiseOfferStats';
// GraphQL
import MeQuery, { MeQueryData } from '../../graphql-ts/queries/MeQuery.graphql';
import LandingPageStatsQuery, {
  LandingPageStatsQueryData,
} from '../../graphql-ts/queries/LandingPageStatsQuery.graphql';
// FUI
import Button from '../../fui/v2/Button';
import Typography from '../../fui/v2/Typography';
// Utils
import { HomePage } from '../../utils-ts/contentful/base';
import { asPath } from '../../utils-ts/pages';
import { Color } from '../../utils-ts/theme';

interface DefinitionSectionProps {
  pageContent: HomePage;
  viewed: boolean;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(20),
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
    },
  },
  horizontalDivider: {
    backgroundColor: Color.FRESH,
    height: 1,
    marginTop: theme.spacing(4),
    width: 64,
  },
  link: {
    alignItems: 'center',
    color: theme.palette.background.default,
    display: 'flex',
  },
  liveCapitalRaisesLabel: {
    alignItems: 'center',
    display: 'flex',
  },
  numbersContainer: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  root: {
    backgroundColor: Color.PREMIUM,
    backgroundPosition: '0 100%',
    backgroundSize: 'cover',
    color: theme.palette.background.default,
    display: 'flex',
    transition: 'background-color 1s ease-out',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      minHeight: '100%',
      '@supports (scroll-snap-align: start)': {
        scrollSnapAlign: 'start',
      },
    },
    'html:not(.no-js) &': {
      backgroundColor: 'rgb(99 99 99)',
    },
  },
  rootAnimated: {
    'html:not(.no-js) &': {
      backgroundColor: Color.PREMIUM,
    },
  },
  sectionHeadlineContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: 0,
      maxWidth: 500,
    },
  },
  sectionHeadlineContent: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    maxWidth: 600,
  },
  slideLeft: {
    left: 0,
    position: 'relative',
    transition: 'all .8s ease-out',
    willChange: 'opacity,transform',
    'html:not(.no-js) &': {
      opacity: 0,
      transform: 'translateX(-150px)',
    },
  },
  slideLeftIn: {
    'html:not(.no-js) &': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  slideUp: {
    bottom: 0,
    position: 'relative',
    transition: 'all .8s ease-out',
    willChange: 'opacity,transform',
    'html:not(.no-js) &': {
      opacity: 0,
      transform: 'translateY(50px)',
    },
  },
  slideUpIn: {
    'html:not(.no-js) &': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  statsContainer: {
    borderLeft: `1px solid ${Color.FRESH}`,
    paddingLeft: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  viewLiveRaises: {
    color: Color.FRESH,
  },
}));

const DefinitionSection = React.forwardRef<unknown, DefinitionSectionProps>(
  function DefinitionSection({ pageContent, viewed, id }, ref) {
    const { data: meData } = useQuery<MeQueryData>(MeQuery);

    const { data: statsData } = useQuery<LandingPageStatsQueryData>(
      LandingPageStatsQuery,
    );

    const activeInvestors =
      statsData && statsData.landingPageStats
        ? statsData.landingPageStats.totalActiveInvestors
        : 0;
    const bidsProcessed =
      statsData && statsData.landingPageStats
        ? statsData.landingPageStats.totalBidsProcessed
        : 0;
    const raisesSupported =
      statsData && statsData.landingPageStats
        ? statsData.landingPageStats.totalCapitalRaisesSupported
        : 0;

    function roundToNearest(num: number, nearest = 25): number {
      return Math.ceil((num + 1) / nearest) * nearest;
    }

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [animateBackground, setAnimateBackground] = useState(false);
    const [displayLeftContent, setDisplayLeftContent] = useState(false);
    const [displayStats1, setDisplayStats1] = useState(false);
    const [displayStats2, setDisplayStats2] = useState(false);
    const [displayStats3, setDisplayStats3] = useState(false);
    const [displayStats4, setDisplayStats4] = useState(false);

    const leftContent = useRef<HTMLDivElement | null>(null);

    function animateIn() {
      setDisplayLeftContent(true);
      setDisplayStats1(true);
      setTimeout(() => {
        setDisplayStats2(true);
      }, 50);
      setTimeout(() => {
        setDisplayStats3(true);
      }, 100);
      setTimeout(() => {
        setDisplayStats4(true);
      }, 150);
    }

    useEffect(() => {
      if (viewed && leftContent.current) {
        setAnimateBackground(true);
        setTimeout(
          () => {
            animateIn();
          },
          smDown ? 0 : 400,
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewed]);

    const classes = useStyles();

    return (
      <section
        className={clsx(
          classes.root,
          animateBackground ? classes.rootAnimated : '',
        )}
        id={id}
        style={{
          backgroundImage: 'url(/images/vectors/home-overlay.svg)',
        }}
        ref={ref as React.RefObject<HTMLElement>}
      >
        <Container className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div
                className={clsx(
                  classes.slideLeft,
                  displayLeftContent ? classes.slideLeftIn : '',
                )}
              >
                <div
                  className={classes.sectionHeadlineContainer}
                  ref={leftContent}
                >
                  <Typography className={classes.subtitle} variant="heading-sm">
                    What is Fresh Equities?
                  </Typography>
                  <Typography variant="display-xl">
                    {pageContent.section2Title}
                  </Typography>

                  <div className={classes.horizontalDivider} />

                  <Typography
                    className={classes.sectionHeadlineContent}
                    variant="body-xl"
                  >
                    {pageContent.section2Content}
                  </Typography>

                  <Link href={asPath.capitalRaises(!!(meData && meData.me))}>
                    <a>
                      <Button
                        className={classes.viewLiveRaises}
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        variant="text"
                      >
                        View Live Raises
                      </Button>
                    </a>
                  </Link>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={5} className={classes.numbersContainer}>
                <Grid item xs={12} sm={6}>
                  <div
                    className={clsx(
                      classes.slideUp,
                      displayStats1 ? classes.slideUpIn : '',
                    )}
                  >
                    <div className={classes.statsContainer}>
                      <Typography variant="display-xl">
                        {numeral(roundToNearest(activeInvestors)).format(
                          '0,0a+',
                        )}
                      </Typography>
                      <Typography variant="body-xl">
                        {pageContent.section2InvestorText}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div
                    className={clsx(
                      classes.slideUp,
                      displayStats2 ? classes.slideUpIn : '',
                    )}
                  >
                    <div className={classes.statsContainer}>
                      <Typography variant="display-xl">
                        {numeral(roundToNearest(bidsProcessed, 25000)).format(
                          '$0,0a+',
                        )}
                      </Typography>
                      <Typography variant="body-xl">
                        {pageContent.section2BidsText}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div
                    className={clsx(
                      classes.slideUp,
                      displayStats3 ? classes.slideUpIn : '',
                    )}
                  >
                    <div className={classes.statsContainer}>
                      <Typography variant="display-xl">
                        {numeral(roundToNearest(raisesSupported, 25)).format(
                          '0,0a+',
                        )}
                      </Typography>
                      <Typography variant="body-xl">
                        {pageContent.section2TransactionsText}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div
                    className={clsx(
                      classes.slideUp,
                      displayStats4 ? classes.slideUpIn : '',
                    )}
                  >
                    <div
                      className={clsx(
                        classes.statsContainer,
                        classes.liveCapitalRaisesLabel,
                      )}
                    >
                      <RaiseOfferStats variant="dark" />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    );
  },
);

export default DefinitionSection;
