import { useQuery } from '@apollo/client';
import { useState } from 'react';
// Graphql
import MePermissionsQuery, {
  MePermissionsQueryData,
} from '../../graphql-ts/queries/MePermissionsQuery.graphql';

export function useFindPermission(search: string) {
  const [loading, setLoading] = useState(true);

  const { data } = useQuery<MePermissionsQueryData, undefined>(
    MePermissionsQuery,
    {
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    },
  );

  if (data && data.mePermissions) {
    return {
      granted: data.mePermissions.includes(search),
      loading: false,
    };
  }

  return { granted: false, loading };
}

export function usePermission(): { loading: boolean; permissions: string[] } {
  const [loading, setLoading] = useState(true);

  const { data } = useQuery<MePermissionsQueryData, undefined>(
    MePermissionsQuery,
    {
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    },
  );

  if (data && data.mePermissions) {
    return { loading: false, permissions: data.mePermissions };
  }

  return { loading, permissions: [] };
}
