import React, { FC } from 'react';

interface AnchorWrapperProps {
  className?: string;
  href?: string | null;
  rel?: string;
  target?: string;
}

const AnchorWrapper: FC<AnchorWrapperProps> = ({
  children,
  href,
  ...props
}) => {
  if (href) {
    return (
      <a {...props} href={href}>
        {children}
      </a>
    );
  }

  return <>{children}</>;
};

export default AnchorWrapper;
